import Client from "./Clients/AxiosClient";

const resource = "/api/v1/admin/bundles";
const productBundle = "api/v1/admin/product-bundles";

export default {
  get(page, perPage, search) {
    return Client.get(`${resource}`, {
      params: {
        page,
        per_page: perPage,
        search,
        sort_field: "created_at",
        sort_direction: "asc",
      },
    });
  },
  getDetail(id) {
    return Client.get(`${resource}/${id}`);
  },
  create(payload) {
    return Client.post(`${resource}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  update(id, payload) {
    return Client.post(`${resource}/update/${id}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  delete(id) {
    return Client.delete(`${resource}/${id}`);
  },

  createProductBundle(payload) {
    return Client.post(`${productBundle}/create-multiple`, payload);
  },
  deleteProductBundle(id) {
    return Client.delete(`${productBundle}/${id}`);
  },
};
