import Client from "./Clients/AxiosClient";
const base = "/api/v1/admin/products";
const detail = "/api/v1/admin/product-details";
const type = "/api/v1/product_type";

export default {
  get(type, search) {
    return Client.get(`${base}`, {
      params: {
        type,
        search,
      },
    });
  },
  create(payload) {
    return Client.post(`${base}`, payload);
  },
  getDetail(id) {
    return Client.get(`${base}/${id}`);
  },
  update(id, payload) {
    return Client.put(`${base}/${id}`, payload);
  },
  delete(id) {
    return Client.delete(`${base}/${id}`);
  },
  createDetail(id, payload) {
    return Client.post(`${detail}/${id}`, payload);
  },
  getDetailsDetail(id, detail_id) {
    return Client.get(`${detail}/${id}/${detail_id}`);
  },
  updateDetail(id, payload) {
    return Client.put(`${detail}/${id}`, payload);
  },
  deleteDetail(id) {
    return Client.delete(`${detail}/${id}`);
  },
  setType(payload) {
    return Client.post(`${type}`, payload);
  },
};
